body,
html {
  margin: 0;
  padding: 0;
}

.App {
  background-image: url(./assets/abstract_flow_background_0407.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
}

.banner {
  width: 80%;
  height: 65%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 30vh;
  align-items: center;
  align-content: center;
}
